/* BASE LAYOUT */

html, body {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

ul.nav li.dropdown:hover ul.dropdown-menu, ul.nav li.dropdown.touchOpened ul.dropdown-menu {
  display: block;
  margin-top: 0
}

#header {
  padding-top: 10px;
}

#header-right {
  text-align: right;
}

.navigation {
  margin-bottom: 0;
}

.breadcrumb {
  margin-bottom: 0;
}

#content, #contentNoJs {
  padding: 30px 10px 30px 10px;
}


#content .container {
  background: transparent;
}

#sidebarLeft {
  background: transparent;
}

#sidebarRight {
  background: transparent;
}


#mainContent {
  padding-bottom: 20px;
}

#footerNav .container {
  background: #e6e6e6;
}

.footerNav-content {
  background: #e6e6e6;
}

#footer {
  margin-bottom: 10px;
}

#footer-left {
  background: #667789;
  background: var(--mainColor);
}

#footer-right {
  background: #667789;
  background: var(--mainColor);
  text-align: right;
}

/* ***************************************************** */
/* Flexgrid                                              */
/* ***************************************************** */

.flexBox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin: 0 -7px 0 -8px;
}

#carousel-anwendung .flexBox {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flexBox > div {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  margin: 7px 7px 8px 8px;
}

.flexBox .flexItem {
  margin: 7px 0 8px 0;
  padding: 0;
  border: none;
}

.flexItem > div {
  height: 100%;
  margin: 0 7px 0 8px;
}

.flexBox--shrink > {
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

/* IN A WIDE COLUMN */

.col-md-9 > .flexBox > div,
.col-md-9 > .flexBox--triple > div {
  -webkit-flex-basis: 33.33333333%;
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
}

.col-md-9 > .flexBox--dual > div {
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

@media (min-width: 435px) and (max-width: 634px) {
  .col-md-9 > .flexBox > div {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 434px) {
  .col-md-9 > .flexBox > div {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

/* IN A SMALL COLUMN */

.col-md-6 > .flexBox > div,
.col-md-6 > .flexBox--triple > div {
  -webkit-flex-basis: 33.33333333%;
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
}

.col-md-6 > .flexBox--dual > div {
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

@media (min-width: 415px) and (max-width: 604px), (min-width: 992px) and (max-width: 1199px) {
  .col-md-6 > .flexBox > div {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 414px) {
  .col-md-6 > .flexBox > div {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

/* ************************************************************************************************************************************************************ */
/* for extra small devices - Phones (<768px)																																			*/
/* ************************************************************************************************************************************************************	*/
@media (max-width: 767px) {
  h2,
  .h2 {
    font-size: 18px;
  }
}

/* ************************************************************************************************************************************************************ */
/* for small devices - Tablets																																			*/
/* ************************************************************************************************************************************************************	*/
@media (min-width: 768px) and (max-width: 991px) {
  h2,
  .h2 {
    font-size: 18px;
  }
}

/* ************************************************************************************************************************************************************ */
/* for medium devices - Medium Desktops																																		*/
/* ************************************************************************************************************************************************************	*/
@media (min-width: 992px) and (max-width: 1199px) {

}

/* ************************************************************************************************************************************************************ */
/* for large devices - Desktops >= 1200px																																	*/
/* ************************************************************************************************************************************************************	*/
@media (min-width: 1200px) {

}

/* ************************************************************************************************************************************************************ */
/* for devices >= 768px - Tablets, Desktops																																			*/
/* ************************************************************************************************************************************************************	*/
@media (min-width: 768px) {

}

/* ************************************************************************************************************************************************************ */
/* for small devices < 992px - Phones, Tablets																																				*/
/* ************************************************************************************************************************************************************	*/
@media (max-width: 991px) {

}

/* ************************************************************************************************************************************************************ */
/* for devices >= 992px - Desktops																																				*/
/* ************************************************************************************************************************************************************	*/
@media (min-width: 992px) {

}

/* ************************************************************************************************************************************************************ */
/* for devices < 1200px - Phones, Tablets, Desktops < 1200px																																				*/
/* ************************************************************************************************************************************************************	*/
@media (max-width: 1199px) {

}