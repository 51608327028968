.switch {
    margin-top: 9px;
    display: inline-block;
    height: 17px;
    position: relative;
    width: 30px;
}

.switch input {
    display:none;
}

.checkboxContainer {
    padding-top: 20px;
}

.slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
}

.slider:before {
    background-color: #fff;
    bottom: 2px;
    content: "";
    height: 13px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 13px;
}

input:checked + .slider {
    background-color: #66bb6a;
}

input:checked + .slider:before {
    transform: translateX(10px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

#nurMandant #nurMandantLabel {
    margin-top: 31px;
}