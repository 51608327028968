.backgroundColorTransparent {
    background-color: transparent;
}

.backgroundColorGrey {
    background-color: #e6e6e6;
}

.backgroundColorRed {
    background-color: #bb3655;
}

.backgroundColorGreen {
    background-color: #82c67d;
}

.backgroundColorLightblue {
    background-color: #7ab3d1;
}

.backgroundColorOrange {
    background-color: #f68c58;
}

.backgroundColorCyan {
    background-color: #1bb194;
}

.backgroundColorBrown {
    background-color: #9a7656;
}

.backgroundColorLightRed {
    background-color: #f24f54;
}

.backgroundColorWhite {
    background-color: #FFFFFF;
}

.textColorBlack {
    color: #000;
}

.textColorWhite {
    color: #fff;
}

.textColorRed {
    color: red;
}

.textColorGreen {
    color: green;
}

.carousel-control {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    filter: alpha(opacity=50);
    opacity: 0.5;
}

.carousel-control.left {
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
    background-repeat: repeat-x;
}

.carousel-control.right {
    right: 0;
    left: auto;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
    background-repeat: repeat-x;
}

.carousel-control:hover, .carousel-control:focus {
    color: #fff;
    text-decoration: none;
    filter: alpha(opacity=90);
    outline: 0;
    opacity: 0.9;
}

.carousel-control .icon.prev,
.carousel-control .icon.next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
}

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
    left: 50%;
    margin-left: -10px;
}

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
    right: 50%;
    margin-right: -10px;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    font-family: serif;
    line-height: 1;
}

.carousel-control .icon-prev:before {
    content: '\2039';
}

.carousel-control .icon-next:before {
    content: '\203a';
}

.mm .mm-close:after {
    right: 23px;
}

.mm-opened .hamburger__container {
    width: 100%;
}

.mm-panels > .mm-panel:after {
    height: 0;
}

.mm-panels > .mm-panel > .mm-listview {
    margin: 20px -20px 0;
}

.menu-horizontal {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-horizontal li {
    display: inline-block;
}

.menu-horizontal li:not(:first-child):before {
    content: "| ";
}

.hamburger {
    padding: 10px 10px;
    display: block;
    cursor: pointer;
    -webkit-transition-property: opacity, -webkit-filter;
    transition-property: opacity, -webkit-filter;
    -o-transition-property: opacity, filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    -webkit-transition-duration: .15s;
    -o-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background: #667789;
    background: var(--mainColor);
    margin: 0;
    overflow: visible;
    outline: none;
    border-radius: 0 0 0 5px;
    border: 1px solid #e6e6e6;
    border-top: none;
    border-right: none;
    float: right;
}

.hamburger-box {
    position: relative;
    top: +2px;
    z-index: 100;
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger__container {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
}

.hamburger:hover {
    opacity: 1;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform, -o-transform;
    -webkit-transition-duration: .15s;
    -o-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
}

.hamburger-inner:after, .hamburger-inner:before {
    content: "";
    display: block;
}

.hamburger-inner:before {
    top: -10px;
}

.hamburger-inner:after {
    bottom: -10px;
}

.hamburger--spin .hamburger-inner {
    -webkit-transition-duration: .22s;
    -o-transition-duration: .22s;
    transition-duration: .22s;
    -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -o-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner:after {
    -webkit-transition: bottom 0.1s ease-in 0.25s, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s ease-in 0.25s, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -o-transition: bottom 0.1s 0.25s ease-in, -o-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -o-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner:before {
    -webkit-transition: top .1s ease-in .25s, opacity .1s ease-in;
    -o-transition: top .1s .25s ease-in, opacity .1s ease-in;
    transition: top .1s ease-in .25s, opacity .1s ease-in;
}

.hamburger--spin .hamburger-inner .is-active .hamburger-inner {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
    -webkit-transition-delay: .12s;
    -o-transition-delay: .12s;
    transition-delay: .12s;
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin .hamburger-inner .is-active .hamburger-inner:before {
    top: 0;
    opacity: 0;
    -webkit-transition: top .1s ease-out, opacity .1s ease-out .12s;
    -o-transition: top .1s ease-out, opacity .1s .12s ease-out;
    transition: top .1s ease-out, opacity .1s ease-out .12s;
}

.hamburger--spin .hamburger-inner .is-active .hamburger-inner:after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    -o-transition: bottom 0.1s ease-out, -o-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    transition: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s, -webkit-transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s, -o-transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
}

.hamburger--spin.is-active .hamburger-inner {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
    -webkit-transition-delay: .12s;
    -o-transition-delay: .12s;
    transition-delay: .12s;
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner:after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    -o-transition: bottom 0.1s ease-out, -o-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    transition: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s, -webkit-transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s, -o-transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
}

.hamburger--spin.is-active .hamburger-inner:before {
    top: 0;
    opacity: 0;
    -webkit-transition: top .1s ease-out, opacity .1s ease-out .12s;
    -o-transition: top .1s ease-out, opacity .1s .12s ease-out;
    transition: top .1s ease-out, opacity .1s ease-out .12s;
}

@media (max-width: 767px) {
    .mm-navbar > form {
        padding: 0 10px;
    }
    .mm-navbar .input-group-btn .btn {
        margin-top: 0;
    }
    .mm-menu .mm-tileview.mm-listview > li.mm-tile-s {
        width: 25%;
        padding: 0;
        height: auto;
    }
    .mm-menu .mm-tileview.mm-listview > li.mm-tile-s > a {
        padding: 10%;
        line-height: 1em;
        position: static;
    }
    .mm-menu .mm-tileview.mm-listview > li.mm-tile-s > a > img {
        width: 100%;
        height: auto;
    }
    .mm-tileview.mm-listview + .mm-listview {
        margin-top: 0;
    }
}

.modal-header {
    background: #667789;
    background: var(--mainColor);
    color: white;
}

.modal-header .close {
    color: #fff;
    opacity: 1;
}

.modal-body .well {
    background: #E6E6E6;
}

.modalEdit, .modalDelete {
    cursor: pointer;
}

.modal-success .modal-header {
    background: #AAD797;
    color: black;
}

.modal-error .modal-header {
    background: #D1423C;
    color: black;
}

.modal-lg {
    margin: 0 auto;
}

@media (max-width: 767px) {
    .modal-dialog {
        top: 50px;
    }
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background: #667789;
    background: var(--mainColor);
    border-color: #667789;
    border-color: var(--mainColor);
    color: #fff;
    cursor: default;
    z-index: 2;
}

.pagination > li a,
.pagination > li span {
    color: #333333;
    background: #FFFFFF;
    border: 1px solid #8c8c8c;
}

.pagination > li a:hover, .pagination > li a:focus,
.pagination > li span:hover,
.pagination > li span:focus {
    font-weight: bold;
    background: #e6e6e6;
    border-color: #e6e6e6;
}

.panel_ansprechpartner a {
    border-color: transparent;
    width: 100%;
}

.panel_ansprechpartner img {
    margin-right: 10px;
}

.panel_anwendung .strike::after {
    border-color: white;
}

.panel_anwendung .panel-bluegrey {
    padding: 0;
}

.panel-bluegrey {
    border-color: #667789;
    border-color: var(--mainColor);
}

.panel-bluegrey a .panel-heading {
    background: #667789;
    background: var(--mainColor);
    color: white;
}

.panel-bluegrey a .panel-heading:hover, .panel-bluegrey a .panel-heading:focus {
    color: white;
}

.panel-bluegrey-footer + .panel-collapse > .panel-body {
    border-bottom-color: #667789;
    border-bottom-color: var(--mainColor);
}

.panel-bluegrey > .panel-heading {
    color: white;
    background: #667789;
    background: var(--mainColor);
    border-color: #667789;
    border-color: var(--mainColor);
}

.panel-bluegrey > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #667789;
    border-top-color: var(--mainColor);
}

.panel-bluegrey > .panel-heading .badge {
    /*color: #f5f5f5;*/
    color: darkgray;
    background-color: lightgrey;
}

.panel-bluegrey > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #667789;
    border-bottom-color: var(--mainColor);
}

.panel-danger {
    border-color: #A9447B;
}

.panel-grey {
    border-color: #888888;
}

.panel-uebersicht .beitragBild {
    margin: 0 auto 5px auto;
}

.panel-uebersicht .btn.glyphicon {
    margin: 0 0 15px 0;
}

.panel-uebersicht div .beitragThema {
    font-size: 14px;
    font-weight: bold;
}

@media (max-width: 767px) {
    .panel-uebersicht div .beitragThema {
        height: auto;
    }
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    min-width: 230px;
    max-width: 276px;
    padding: 1px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: left;
    white-space: normal;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #e6e6e6;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.popover.bottom {
    margin-top: 10px;
}

.popover.bottom > .arrow {
    top: -11px;
    left: 6%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #e6e6e6;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.popover.bottom > .arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #e6e6e6;
}

.popover-content {
    font-size: 12px;
    padding: 3px 9px 8px;
}

.popover.left {
    margin-left: -10px;
}

.popover.left > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #e6e6e6;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.popover.left > .arrow:after {
    right: 1px;
    bottom: -10px;
    content: " ";
    border-right-width: 0;
    border-left-color: #e6e6e6;
}

.popover.right {
    margin-left: 10px;
}

.popover.right > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: #e6e6e6;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-left-width: 0;
}

.popover.right > .arrow:after {
    bottom: -10px;
    left: 1px;
    content: " ";
    border-right-color: #e6e6e6;
    border-left-width: 0;
}

.popover-title {
    padding: 8px 8px 3px;
    margin: 0;
    font-size: 14px;
    background-color: #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 5px 5px 0 0;
}

.popover-title h4 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
}

.popover-title span {
    font-size: 12px;
}

.popover.top {
    margin-top: -10px;
}

.popover.top > .arrow {
    bottom: -11px;
    left: 6%;
    margin-left: -11px;
    border-top-color: #e6e6e6;
    border-top-color: rgba(0, 0, 0, 0.25);
    border-bottom-width: 0;
}

.popover.top > .arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-top-color: #e6e6e6;
    border-bottom-width: 0;
}

.popover > .arrow {
    border-width: 11px;
}

.popover > .arrow, .popover > .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.popover > .arrow:after {
    content: "";
    border-width: 10px;
}

.popover label {
    font-size: 13px;
}

/* BASE LAYOUT */
html, body {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

ul.nav li.dropdown:hover ul.dropdown-menu, ul.nav li.dropdown.touchOpened ul.dropdown-menu {
    display: block;
    margin-top: 0;
}

#header {
    padding-top: 10px;
}

#header-right {
    text-align: right;
}

.navigation {
    margin-bottom: 0;
}

.breadcrumb {
    margin-bottom: 0;
}

#content, #contentNoJs {
    padding: 30px 10px 30px 10px;
}

#content .container {
    background: transparent;
}

#sidebarLeft {
    background: transparent;
}

#sidebarRight {
    background: transparent;
}

#mainContent {
    padding-bottom: 20px;
}

#footerNav .container {
    background: #e6e6e6;
}

.footerNav-content {
    background: #e6e6e6;
}

#footer {
    margin-bottom: 10px;
}

#footer-left {
    background: #667789;
    background: var(--mainColor);
}

#footer-right {
    background: #667789;
    background: var(--mainColor);
    text-align: right;
}

/* ***************************************************** */
/* Flexgrid                                              */
/* ***************************************************** */
.flexBox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 0 -7px 0 -8px;
}

#carousel-anwendung .flexBox {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flexBox > div {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
    margin: 7px 7px 8px 8px;
}

.flexBox .flexItem {
    margin: 7px 0 8px 0;
    padding: 0;
    border: none;
}

.flexItem > div {
    height: 100%;
    margin: 0 7px 0 8px;
}

.flexBox--shrink > {
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
}

/* IN A WIDE COLUMN */
.col-md-9 > .flexBox > div,
.col-md-9 > .flexBox--triple > div {
    -webkit-flex-basis: 33.33333333%;
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
}

.col-md-9 > .flexBox--dual > div {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
}

@media (min-width: 435px) and (max-width: 634px) {
    .col-md-9 > .flexBox > div {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    }
}

@media (max-width: 434px) {
    .col-md-9 > .flexBox > div {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

/* IN A SMALL COLUMN */
.col-md-6 > .flexBox > div,
.col-md-6 > .flexBox--triple > div {
    -webkit-flex-basis: 33.33333333%;
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
}

.col-md-6 > .flexBox--dual > div {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
}

@media (min-width: 415px) and (max-width: 604px), (min-width: 992px) and (max-width: 1199px) {
    .col-md-6 > .flexBox > div {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    }
}

@media (max-width: 414px) {
    .col-md-6 > .flexBox > div {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

/* ************************************************************************************************************************************************************ */
/* for extra small devices - Phones (<768px)																																			*/
/* ************************************************************************************************************************************************************	*/
@media (max-width: 767px) {
    h2,
    .h2 {
        font-size: 18px;
    }
}

/* ************************************************************************************************************************************************************ */
/* for small devices - Tablets																																			*/
/* ************************************************************************************************************************************************************	*/
@media (min-width: 768px) and (max-width: 991px) {
    h2,
    .h2 {
        font-size: 18px;
    }
}

/* ************************************************************************************************************************************************************ */
/* for medium devices - Medium Desktops																																		*/
/* ************************************************************************************************************************************************************	*/
/* ************************************************************************************************************************************************************ */
/* for large devices - Desktops >= 1200px																																	*/
/* ************************************************************************************************************************************************************	*/
/* ************************************************************************************************************************************************************ */
/* for devices >= 768px - Tablets, Desktops																																			*/
/* ************************************************************************************************************************************************************	*/
/* ************************************************************************************************************************************************************ */
/* for small devices < 992px - Phones, Tablets																																				*/
/* ************************************************************************************************************************************************************	*/
/* ************************************************************************************************************************************************************ */
/* for devices >= 992px - Desktops																																				*/
/* ************************************************************************************************************************************************************	*/
/* ************************************************************************************************************************************************************ */
/* for devices < 1200px - Phones, Tablets, Desktops < 1200px																																				*/
/* ************************************************************************************************************************************************************	*/
a {
    color: #667789;
    color: var(--mainColor);
}

a .backgroundColorDefault,
a .backgroundColorDefault .strike {
    color: white;
}

a:focus, a:hover {
    color: #667789;
    color: var(--mainColor);
}

a#telefon {
    padding: 5px;
    color: white;
}

.aktuelles {
    font-size: 16px;
    color: #667789;
    color: var(--mainColor);
    height: 266px;
}

.aktuelles h4 {
    margin-top: 0;
}

.aktuellesName {
    position: absolute;
    top: 35%;
    right: 0;
    vertical-align: middle;
}

.anmelde {
    color: white;
    border: 3px solid white;
    height: 20px;
    background: #667789;
    background: var(--mainColor);
    margin-left: 0;
}

.ansprechpartner {
    margin-left: -10px;
}

.ansprechpartnertext {
    margin-left: 4px;
    float: left;
    background: red;
}

.anwendung {
    background: white;
    height: 60px;
    width: 60px;
    margin: 5px;
}

.anwendung strong {
    font-size: 10px;
}

.backgroundNavbar {
    background: #7a8fa4;
    background: var(--navbarColor);
}

.backgroundColorDefault {
    background: #667789;
    background: var(--mainColor);
}

.bildAnsprechpartner {
    height: 123px;
}

.bildHintergrund {
    padding: 0 auto;
    display: inline-block;
    text-align: center;
}

.bildHintergrund img {
    vertical-align: middle;
    width: 100%;
}

.box {
    border: 2px solid #667789;
    border: 2px solid var(--mainColor);
    padding: 5px;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0;
}

.btn > .caret {
    border-top-color: #000 !important;
}

.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.btn:hover, .btn:focus, .btn.focus {
    color: #fff;
    text-decoration: none;
}

.btn:active, .btn.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-default {
    color: #FFFFFF;
    background: #667789;
    background: var(--mainColor);
    border-color: #667789;
    border-color: var(--mainColor);
    background-image: none;
    background-repeat: no-repeat;
}

.btn-default .badge {
    color: #fff;
    background-color: #333;
}

.btn-filter {
    padding-top: 3px;
    padding-bottom: 3px;
}

.btn-login-header {
    border: 1px solid white;
}

.btn-sm {
    border-radius: 3px;
    font-size: 12px;
    line-height: 1.5;
    padding: 5px 10px;
}

.btn-toolbar .btn-group .btn {
    border-radius: 4px;
    border-color: #dcdcdc;
    background-color: #FFFFFF;
}

.btn-toolbar .btn-group .btn:hover {
    color: #000000;
    background-color: #dcdcdc;
}

.btn-toolbar .btn-group .btn.active {
    background-color: #dcdcdc;
}

.btn-xs {
    border-radius: 3px;
    font-size: 12px;
    line-height: 1.5;
    padding: 1px 5px;
}

.btn-filter, .btn-search, .btn-forward {
    margin-top: 0;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    pointer-events: none;
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 0.65;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    color: #FFFFFF;
    background: #859bb2;
    background: var(--hoverColor);
    border-color: #859bb2;
    border-color: var(--hoverColor);
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    background-image: none;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
    background: #667789;
    background: var(--mainColor);
    border-color: #667789;
    border-color: var(--mainColor);
}

#contentHeader .btn-caption {
    font-size: 12px;
}

#contentHeader .caption-text {
    font-size: 12px;
    font-weight: bold;
}

#contentHeader .caption-title {
    margin-top: 5px;
    font-size: 13px;
    font-weight: bold;
}

#contentHeader .chb-box {
    background-color: white;
    margin: 20px;
}

@media (max-width: 767px) {
    #contentHeader {
        display: none;
    }
}

.drag {
    color: #006600;
    border: 1px solid #006600;
    padding: 3px;
    cursor: pointer;
}

.dragLayout {
    color: #006600;
}

.error {
    color: red;
}

.experte {
    font-size: 16px;
    padding: 5px;
    font-weight: bold;
}

.expertennummer {
    padding: 5px;
}

.expertennummer .img {
    margin-top: 5px;
}

.fav-container {
    height: 20px;
    width: 20px;
    background-size: cover;
    cursor: pointer;
    margin-bottom: 5px;
}

.fav-no {
    background-image: url("../../img/icons/pictogramme/png/impuls-portal_icon_60x60-favoriten_negativ-21.png");
}

.fav-yes {
    background-image: url("../../img/icons/pictogramme/png/impuls-portal_icon_60x60-favoriten-21.png");
}

.file-caption {
    margin-top: 10px;
}

#footer {
    color: white;
}

#footer a {
    color: white;
    text-decoration: none;
}

#footer a:hover {
    text-decoration: underline;
}

#footer-left, #footer-right {
    padding: 10px;
}

.footerNav-content a {
    color: black;
}

.footerNav-content li.title {
    margin-bottom: 10px;
    text-transform: uppercase;
}

.footerNav-content ul.category {
    padding: 15px;
    list-style: none;
}

#formSuche {
    margin-bottom: 10px;
}

.frontend .carousel-inner {
    margin-top: 15px;
}

.geschlecht {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h4 {
    text-transform: uppercase;
}

h4 > span.strike {
    display: block;
    margin-bottom: 30px;
}

.header2Backend .strike::after {
    content: '';
    border-bottom: 2.6px solid white;
    position: absolute;
    left: 0;
    bottom: -10%;
}

.header2Ueberschrift {
    margin-left: 10px;
    padding-top: 10px;
}

.header-right {
    font-size: 5px;
}

.headLogin {
    margin: 10px;
}

.highlighted {
    background: darkgrey;
}

.hinweisPflichtfelder {
    font-style: italic;
}

.icon-dauer,
.icon-schwierigkeit {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.img-mittig {
    margin: 0 auto;
}

.input-success {
    border-color: #3c763d;
}

.input-warning {
    border-color: #8a6d3b;
}

.input-danger {
    border-color: #a94442;
}

.kontakt .breadcrumb {
    margin-left: 20px;
}

.kontakt form {
    margin-left: 5px;
}

.kontakt form .col-md-12 {
    padding: 0;
}

.loginForm a {
    margin-top: 15px;
}

.loginHeader {
    /*display:none;*/
    padding: 0 15px 15px 15px;
    position: absolute;
    bottom: 10%;
    right: 5%;
    width: 35%;
    max-width: 1170px;
    color: #fff;
    /*text-align: center;*/
    /*line-height: 2.5em;*/
    border-top: 1px solid #ababab;
    background: #667789;
    /* Fallback IE 6-8 */
    background: var(--mainColor);
    /* Fallback IE 6-8 */
}

.loginHeader a {
    margin-top: 10px;
    text-decoration: none;
    color: white;
}

.loginHeader input {
    color: #667789;
    color: var(--mainColor);
    margin: 5px 0 5px 0;
}

.loginHeader label {
    display: none;
}

.loginHeader h4 {
    padding-left: 16px;
}

.loginHeader .btn-login {
    float: left;
    border: 1px solid white;
}

.loginHeader .form-group {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .loginHeader {
        position: static;
        width: auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .loginHeader {
        bottom: auto;
        top: 0;
    }
}

.logo-streit {
    width: 240px;
}

.logo-portal {
    width: 90%;
}

@media (max-width: 767px) {
    .logo-portal {
        width: 80%;
    }
}

#mainContent #aktuelles .carousel-caption {
    color: black;
    background: rgba(255, 255, 255, 0.7);
    width: 100%;
    padding-left: 30px;
    text-align: left;
    left: 0;
    right: 0;
    padding-bottom: 15px;
}

#mainContent #aktuelles .carousel-caption p {
    font-size: 16px;
}

#mainContent #aktuelles .carousel-indicators {
    right: 5%;
    left: auto;
    width: auto;
    margin-left: 0;
}

#mainContent #aktuelles .carousel-indicators .active {
    background: #667789;
    background: var(--mainColor);
    height: 12px;
    margin: 0;
    width: 12px;
}

#mainContent #aktuelles .carousel-indicators li {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #667789;
    border: 1px solid var(--mainColor);
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    height: 10px;
    margin: 0;
    text-indent: -999px;
    width: 10px;
    text-align: left;
}

#mainContent .anwendung .col-md-4 {
    padding: 5px;
}

#mainContent .btn-bearbeiten {
    margin-top: 10px;
    margin-left: 5px;
}

#mainContent .btn-search-aktuelles {
    margin-top: 0;
}

#mainContent .fileinput-remove-button {
    margin-top: 10px;
}

#mainContent h4 {
    text-transform: uppercase;
}

#mainContent li {
    list-style: circle;
}

#mainContent p {
    font-size: 12px;
}

#mainContent .tooltip {
    opacity: 1;
}

#mainContent .tooltip-inner {
    opacity: 1;
    color: black;
    background: #E6E6E6;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid #919191;
}

#mainContent .tooltip-arrow {
    border-bottom-color: #919191;
}

.mousePointer {
    cursor: pointer;
}

.noBorder {
    border: 0 solid grey !important;
}

p.filterPanel {
    height: 5px;
}

.preNews {
    border: transparent;
    background: transparent;
    max-width: 523px;
    overflow: auto;
    white-space: pre-wrap;
    padding: 0 1%;
}

.profil {
    margin-top: 0;
    color: white;
}

.profilbox {
    margin-top: 10px;
    height: 60px;
    background: white;
    font-size: 10px;
    padding: 2px;
}

.profilHeader {
    padding: 15px;
}

.profilIcon {
    left: 33%;
    top: 33%;
}

.profilIconfeld {
    width: 40px;
    height: 40px;
    color: #667789;
    color: var(--mainColor);
    background: white;
}

.profiluebersicht .anwendung {
    margin-left: -20px;
}

.overlay img {
    display: block;
}

.responsive-video {
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
}

.responsive-video iframe {
    width: 100%;
    max-width: 868px;
    max-height: 434px;
}

.responsive-video video {
    width: 100%;
    max-width: 868px;
    max-height: 434px;
}

.rollover {
    height: 60px;
    width: 60px;
    color: transparent;
    background: transparent;
    font-size: 10px;
    text-align: center;
}

.rollover:hover {
    height: 60px;
    width: 60px;
    color: white;
    background: #667789;
    background: var(--mainColor);
    opacity: 0.7;
}

.rollover:hover img {
    display: none;
}

.rueck {
    margin-left: 4%;
    font-size: 12px;
}

.schatten,
.shadow:hover {
    -webkit-box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.44);
    -moz-box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.44);
    box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.44);
}

#sidebarCalendar h2 {
    font-size: 18px;
}

.sidebarRight h4 > span.strike, .sidebarLeft h4 > span.strike {
    display: block;
    margin-bottom: 20px;
}

span#mail {
    padding: 5px;
}

span#mail a {
    color: white;
}

.strike {
    position: relative;
}

.strike::after {
    content: '';
    border-bottom: 2.6px solid #667789;
    border-bottom: 2.6px solid var(--mainColor);
    position: absolute;
    left: 0;
    bottom: -30%;
    width: 38px;
}

.styleanwendung a:hover {
    text-decoration: none;
}

.styleanwendung .btn {
    margin: 10px;
}

.styleanwendung .carousel-control {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    filter: alpha(opacity=50);
    opacity: 0.5;
}

.styleanwendung .carousel-control:hover, .styleanwendung .carousel-control:focus {
    color: #fff;
    text-decoration: none;
    filter: alpha(opacity=90);
    outline: 0;
    opacity: 0.9;
}

.styleanwendung .carousel-control .icon-prev,
.styleanwendung .carousel-control .icon-next,
.styleanwendung .carousel-control .glyphicon-chevron-left,
.styleanwendung .carousel-control .glyphicon-chevron-right {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
}

.styleanwendung .carousel-control .icon-prev,
.styleanwendung .carousel-control .glyphicon-chevron-left {
    left: 50%;
    margin-left: -10px;
}

.styleanwendung .carousel-control .icon-next,
.styleanwendung .carousel-control .glyphicon-chevron-right {
    right: 50%;
    margin-right: -10px;
}

.styleanwendung .carousel-control .icon-prev,
.styleanwendung .carousel-control .icon-next {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    font-family: serif;
    line-height: 1;
}

.styleanwendung .carousel-control .icon-prev:before {
    content: '\2039';
}

.styleanwendung .carousel-control .icon-next:before {
    content: '\203a';
}

.styleanwendung .row {
    margin-left: 10px;
}

.suchFeld {
    padding: 10px;
    border-radius: 4px;
}

table .btn-link,
table .btn-left:hover {
    color: #FFF;
}

.team {
    margin: 5px;
    padding: 5px;
}

.textCenter {
    text-align: center;
}

@media (max-width: 767px) {
    #navigation, #header-right, #footerNav > div:not(#footer) {
        display: none;
        visibility: hidden;
    }
    #header2-content {
        display: none;
    }
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background: #667789;
    background: var(--mainColor);
    border-color: #667789;
    border-color: var(--mainColor);
    color: #fff;
    cursor: default;
    z-index: 2;
}

.pagination > li a,
.pagination > li span {
    color: #333333;
    background: #FFFFFF;
    border: 1px solid #8c8c8c;
}

.pagination > li a:hover, .pagination > li a:focus,
.pagination > li span:hover,
.pagination > li span:focus {
    font-weight: bold;
    background: #e6e6e6;
    border-color: #e6e6e6;
}

#navigation nav {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

#navigation nav a {
    color: white;
}

#navigation nav .dropdown-menu a {
    color: #333;
}

#navigation nav .input-group a {
    color: #667789;
    color: var(--mainColor);
}

#navigation .affixed {
    position: fixed;
}

.navbar-default {
    border: 0;
    background: none;
    filter: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.navbar-default .navbar-nav > .active a,
.navbar-default .navbar-nav > .open a {
    background-image: linear-gradient(to bottom, #FFFFFF 0px, #FFFFFF 100%);
    background-repeat: repeat-x;
}

.navbar-default .navbar-nav .navbar-brand,
.navbar-default .navbar-nav .navbar-nav > li > a,
.navbar-default .navbar-nav .navbar-nav > li > div > a {
    color: #fff;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-nav > li:hover,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    background: #859bb2;
    background: var(--hoverColor);
    color: #FFFFFF;
}

.nav-tabs li.active {
    font-weight: bold;
}

.switch {
    margin-top: 9px;
    display: inline-block;
    height: 17px;
    position: relative;
    width: 30px;
}

.switch input {
    display: none;
}

.checkboxContainer {
    padding-top: 20px;
}

.slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
}

.slider:before {
    background-color: #fff;
    bottom: 2px;
    content: "";
    height: 13px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 13px;
}

input:checked + .slider {
    background-color: #66bb6a;
}

input:checked + .slider:before {
    transform: translateX(10px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

#nurMandant #nurMandantLabel {
    margin-top: 31px;
}

@keyframes floating {
    to {
        top: 1.5rem;
    }
}

#gandalf body {
    background-color: #000;
    color: #fff;
    text-align: center;
    font-family: "Open Sans", serif;
    font-size: 1.3rem;
}

#gandalf body h1 {
    font-size: 2.5rem;
}

#gandalf .message {
    max-width: 700px;
    margin: 5rem auto 0 auto;
}

#gandalf .gandalf {
    position: relative;
    width: 400px;
    height: 400px;
    margin: 0 auto;
    top: 1rem;
    animation: floating 1s infinite alternate ease-in-out;
}

#gandalf .gandalf div {
    position: absolute;
}

#gandalf .gandalf::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 400px;
    background-color: #1a2130;
    border-radius: 50%;
}

#gandalf .gandalf .fireball {
    bottom: -10px;
    left: 50px;
    width: 300px;
    height: 100px;
    border-radius: 50%;
    background: radial-gradient(#efac41, #de8531, #6c1305, black);
    border: 5px solid #000;
}

#gandalf .gandalf .skirt {
    bottom: 50px;
    left: 100px;
    border-bottom: 230px solid #ededed;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    filter: drop-shadow(0 0 6px #d4d4d4);
}

#gandalf .gandalf .skirt::before {
    content: "";
    position: absolute;
    background-color: #ededed;
    width: 100px;
    height: 21px;
    top: 230px;
    left: 0;
    border-bottom-right-radius: 180%;
    border-bottom-left-radius: 100%;
}

#gandalf .gandalf .skirt::after {
    content: "";
    position: absolute;
    background-color: #ededed;
    width: 100px;
    height: 28px;
    top: 230px;
    left: -100px;
    border-bottom-right-radius: 80%;
    border-bottom-left-radius: 180%;
}

#gandalf .gandalf .sleeves::before, #gandalf .gandalf .sleeves::after {
    content: "";
    position: absolute;
    border-bottom: 70px solid #ededed;
    filter: drop-shadow(0 0 6px #d4d4d4);
}

#gandalf .gandalf .sleeves::before {
    top: 130px;
    left: 191px;
    border-left: 100px solid transparent;
    border-right: 40px solid transparent;
    transform: rotate(-34deg);
}

#gandalf .gandalf .sleeves::after {
    top: 127px;
    left: 70px;
    border-left: 40px solid transparent;
    border-right: 100px solid transparent;
    transform: rotate(41deg);
}

#gandalf .gandalf .shoulders {
    background-color: #ededed;
    border-radius: 50%;
    width: 100px;
    height: 130px;
    left: 150px;
    top: 120px;
}

#gandalf .gandalf .shoulders .hand {
    width: 33px;
    height: 26px;
    border-radius: 50%;
    background-color: #ffd8ad;
    top: -6px;
}

#gandalf .gandalf .shoulders .left {
    left: -70px;
    transform: rotate(-20deg);
}

#gandalf .gandalf .shoulders .left::after {
    content: "";
    position: absolute;
    background-color: #e6e6e6;
    width: 126px;
    height: 8px;
    border-radius: 4px;
    transform: rotate(-105deg);
    transform-origin: bottom;
    top: -48px;
    left: -56px;
}

#gandalf .gandalf .shoulders .right {
    right: -70px;
    transform: rotate(20deg);
}

#gandalf .gandalf .shoulders .right::after {
    content: "";
    position: absolute;
    background-color: #bf5507;
    width: 250px;
    height: 5px;
    border-radius: 2.5px;
    transform: rotate(-78deg);
    transform-origin: left;
    bottom: -100px;
    left: 0;
}

#gandalf .gandalf .head {
    width: 80px;
    height: 90px;
    top: 80px;
    left: 160px;
    background-color: #ffd8ad;
    border-radius: 50%;
}

#gandalf .gandalf .head::before, #gandalf .gandalf .head::after {
    content: "";
    position: absolute;
    background-color: #000;
}

#gandalf .gandalf .head::before {
    width: 13px;
    height: 5px;
    border-radius: 3px;
    top: 42px;
    left: 22px;
    transform: rotate(19deg);
}

#gandalf .gandalf .head::after {
    width: 13px;
    height: 5px;
    border-radius: 3px;
    top: 42px;
    right: 22px;
    transform: rotate(-19deg);
}

#gandalf .gandalf .head .hair {
    width: 70px;
    height: 30px;
    background-color: #c2beb5;
    border-radius: 50%;
    top: 0;
    left: 5px;
}

#gandalf .gandalf .head .hair::before, #gandalf .gandalf .head .hair::after {
    content: "";
    position: absolute;
    background-color: #c2beb5;
    filter: drop-shadow(2px 5px 0 #aba599);
}

#gandalf .gandalf .head .hair::before {
    top: 13px;
    left: -16px;
    width: 25px;
    height: 100px;
    border-radius: 34px 15px 20px 100px;
    transform: rotate(8deg);
}

#gandalf .gandalf .head .hair::after {
    top: 13px;
    right: -16px;
    width: 25px;
    height: 100px;
    border-radius: 15px 34px 100px 20px;
    transform: rotate(-10deg);
}

#gandalf .gandalf .head .beard {
    top: 64px;
    left: 5px;
    border-top: 80px solid #c2beb5;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-radius: 30px;
    filter: drop-shadow(2px 5px 0 #aba599);
}

#gandalf .gandalf .head .beard::before {
    content: "";
    position: absolute;
    background-color: pink;
    width: 20px;
    height: 5px;
    border-radius: 40%;
    top: -70px;
    left: -9px;
}

/* ******************************************************************************************************** */
/* calendar
/* ******************************************************************************************************** */
.fc-day-header {
    background: #667789;
    background: var(--mainColor);
    color: #FFFFFF;
}

.fc-day-header a {
    background: #667789;
    background: var(--mainColor);
    color: #FFFFFF;
}

.fc-ltr .fc-basic-view .fc-day-number {
    text-align: left;
    font-size: 18px;
}

.fc-event:hover {
    cursor: pointer;
}

.calLegende {
    display: inline-block;
    width: 10px;
    height: 10px;
}

.fc-center h2 {
    font-size: 20px;
}

.modal-header.hotline {
    background: white;
    color: black;
}

.anzeigeWochentage .btn-group {
    margin-left: 0;
}

#mainContent .anzeigeWochentage .btn {
    margin: 0;
    padding: 3px 6px;
}
