// .carousel
.carousel {
  // .carousel-control
  &-control {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    filter: alpha(opacity=50);
    opacity: 0.5;

    // .carousel-control.left
    &.left {
      background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%);
      background-image: -o-linear-gradient(left, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%);
      background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, .0001)));
      background-image: linear-gradient(to right, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
      background-repeat: repeat-x;
    }

    // .carousel-control.right
    &.right {
      right: 0;
      left: auto;
      background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%);
      background-image: -o-linear-gradient(left, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%);
      background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .0001)), to(rgba(0, 0, 0, .5)));
      background-image: linear-gradient(to right, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
      background-repeat: repeat-x;
    }

    // .carousel-control:hover
    // .carousel-control:focus
    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
      filter: alpha(opacity=90);
      outline: 0;
      opacity: 0.9;
    }

    // .carousel-control .icon.prev
    // .carousel-control .icon.next
    // .carousel-control .glyphicon-chevron-left
    // .carousel-control .glyphicon-chevron-right
    .icon.prev,
    .icon.next,
    .glyphicon-chevron-left,
    .glyphicon-chevron-right {
      position: absolute;
      top: 50%;
      z-index: 5;
      display: inline-block;
    }

    // .carousel-control .icon-prev
    // .carousel-control .glyphicon-chevron-left
    .icon-prev,
    .glyphicon-chevron-left {
      left: 50%;
      margin-left: -10px;
    }

    // .carousel-control .icon-next
    // .carousel-control .glyphicon-chevron-right
    .icon-next,
    .glyphicon-chevron-right {
      right: 50%;
      margin-right: -10px;
    }

    // .carousel-control .icon-prev,
    // .carousel-control .icon-next
    .icon-prev,
    .icon-next {
      width: 20px;
      height: 20px;
      margin-top: -10px;
      font-family: serif;
      line-height: 1;
    }

    // .carousel-control .icon-prev:before
    .icon-prev:before {
      content: '\2039';
    }

    // .carousel-control .icon-next:before
    .icon-next:before {
      content: '\203a';
    }
  }
}