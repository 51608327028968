// .panel
.panel {

  // .panel_ansprechpartner
  &_ansprechpartner {
    // .panel_ansprechpartner a
    a {
      border-color: transparent;
      width: 100%;
    }

    // .panel_ansprechpartner img
    img {
      margin-right: 10px;
    }
  }

  // .panel-anwendung
  &_anwendung {
    // .panel-anwendung .strike::after
    .strike::after {
      border-color: white;
    }

    // .panel-anwendung .panel-bluegrey
    .panel-bluegrey {
      padding: 0;
    }
  }

  // .panel-bluegrey
  //noinspection CssOverwrittenProperties
  &-bluegrey {
    border-color: #667789;
    border-color: var(--mainColor);

    // .panel-bluegrey a
    a {
      // .panel-bluegrey a .panel-heading
      .panel-heading {
        background: #667789;
        background: var(--mainColor);
        color: white;

        &:hover,
        &:focus {
          color: white;
        }
      }
    }

    // .panel-bluegrey .panel-footer
    &-footer {
      // .panel-bluegrey .panel-footer + .panel-collapse
      + .panel-collapse {
        // .panel-bluegrey .panel-footer + .panel-collapse > .panel-body
        > .panel-body {
          border-bottom-color: #667789;
          border-bottom-color: var(--mainColor);
        }
      }
    }

    // .panel-bluegrey .panel-heading
    > .panel-heading {
      color: white;
      background: #667789;
      background: var(--mainColor);
      border-color: #667789;
      border-color: var(--mainColor);

      // .panel-bluegrey .panel-heading + .panel-collapse
      + .panel-collapse {
        // .panel-bluegrey .panel-heading + .panel-collapse > .panel-body
        > .panel-body {
          border-top-color: #667789;
          border-top-color: var(--mainColor);
        }
      }

      // .panel-bluegrey .panel-heading .badge
      .badge {
        /*color: #f5f5f5;*/
        color: darkgray;
        background-color: lightgrey;
      }

    }

    // .panel-bluegrey > .panel-footer
    > .panel-footer {
      // .panel-bluegrey > .panel-footer + .panel-collapse
      + .panel-collapse {
        // .panel-bluegrey > .panel-footer + .panel-collapse > .panel-body
        > .panel-body {
          border-bottom-color: #667789;
          border-bottom-color: var(--mainColor);
        }
      }
    }
  }

  // .panel-danger
  &-danger {
    border-color: #A9447B;
  }

  // .panel-grey
  &-grey {
    border-color: #888888;
  }

  // .panel-uebersicht
  &-uebersicht {
    // .panel-uebersicht .beitragBild
    .beitragBild {
      margin: 0 auto 5px auto;
    }

    // .panel-uebersicht .btn.glyphicon
    .btn.glyphicon {
      margin: 0 0 15px 0;
    }

    // .panel-uebersicht div
    div {
      // .panel-uebersicht div .beitragThema
      .beitragThema {
        font-size: 14px;
        font-weight: bold;

        @include mediaS {
          height: auto;
        }
      }
    }
  }
}