// a
a {
  color: #667789;
  color: var(--mainColor);

  // a .backgroundColorDefault
  // a .backgroundColorDefault .strike
  .backgroundColorDefault,
  .backgroundColorDefault .strike {
    color: white;
  }

  // a:focus
  // a:hover
  &:focus,
  &:hover {
    color: #667789;
    color: var(--mainColor);
  }

  // a#telefon
  &#telefon {
    padding: 5px;
    color: white;
  }
}

// .aktuelles
//noinspection CssOverwrittenProperties
.aktuelles {
  font-size: 16px;
  color: #667789;
  color: var(--mainColor);
  height: 266px;

  // .aktuelles h4
  h4 {
    margin-top: 0;
  }
}

// .aktuellesName
.aktuellesName {
  position: absolute;
  top: 35%;
  right: 0;
  vertical-align: middle;
}

// .anmelde
.anmelde {
  color: white;
  border: 3px solid white;
  height: 20px;
  background: #667789;
  background: var(--mainColor);
  margin-left: 0;
}

// .ansprechpartner {
.ansprechpartner {
  margin-left: -10px;
}

// .ansprechpartnertext
.ansprechpartnertext {
  margin-left: 4px;
  float: left;
  background: red;
}

// .anwendung
.anwendung {
  background: white;
  height: 60px;
  width: 60px;
  margin: 5px;

  // .anwendung strong
  strong {
    font-size: 10px;
  }
}

// .background
.background {
  // .backgroundNavbar
  &Navbar {
    background: #7a8fa4;
    background: var(--navbarColor);
  }

  // .backgroundColorDefault
  &ColorDefault {
    background: #667789;
    background: var(--mainColor);
  }

}

// .bildAnsprechpartner
.bildAnsprechpartner {
  height: 123px;
}

// .bildHintergrund
.bildHintergrund {
  padding: 0 auto;
  display: inline-block;
  text-align: center;

  // .bildHintergrund img
  img {
    vertical-align: middle;
    width: 100%;
  }
}

// .box
.box {
  border: 2px solid #667789;
  //noinspection CssOverwrittenProperties
  border: 2px solid var(--mainColor);
  padding: 5px;
}

// .btn
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0;

  // .btn > .caret
  > .caret {
    border-top-color: #000 !important;
  }

  // .btn:focus
  // .btn:active:focus
  // .btn.active:focus
  // .btn.focus
  // .btn:active.focus
  // .btn.active.focus
  //noinspection CssOverwrittenProperties
  &:focus,
  &:active:focus,
  &.active:focus,
  &.focus,
  &:active.focus,
  &.active.focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  // .btn:hover
  // .btn:focus
  // .btn.focus
  &:hover,
  &:focus,
  &.focus {
    color: #fff;
    text-decoration: none;
  }

  // .btn:active
  // .btn.active
  &:active,
  &.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  // .btn-default
  //noinspection CssOverwrittenProperties
  &-default {
    color: #FFFFFF;
    background: #667789;
    background: var(--mainColor);
    border-color: #667789;
    border-color: var(--mainColor);
    background-image: none;
    background-repeat: no-repeat;
    // .btn-default .badge
    .badge {
      color: #fff;
      background-color: #333;
    }
  }

  // .btn-filter
  &-filter {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  // .btn-login-header
  &-login-header {
    border: 1px solid white;
  }

  // .btn-sm
  &-sm {
    border-radius: 3px;
    font-size: 12px;
    line-height: 1.5;
    padding: 5px 10px;
  }

  // .btn-toolbar
  &-toolbar {
    // .btn-toolbar .btn-group
    .btn-group {
      // .btn-toolbar .btn-group .btn
      .btn {
        border-radius: 4px;
        border-color: #dcdcdc;
        background-color: #FFFFFF;

        // .btn-toolbar .btn-group .btn:hover
        &:hover {
          color: #000000;
          background-color: #dcdcdc;
        }

        // .btn-toolbar .btn-group .btn.active
        &.active {
          background-color: #dcdcdc;
        }
      }
    }
  }

  // .btn-xs
  &-xs {
    border-radius: 3px;
    font-size: 12px;
    line-height: 1.5;
    padding: 1px 5px;
  }

  // .btn-filter
  // .btn-search
  // .btn-forward
  &-filter,
  &-search,
  &-forward {
    margin-top: 0;
  }
}

// .btn.disabled,
// .btn[disabled],
// fieldset[disabled] .btn
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  pointer-events: none;
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}

// .btn-default:hover
// .btn-default:focus
// .btn-default.focus
// .btn-default:active
// .btn-default.active
// .open > .dropdown-toggle.btn-default
//noinspection CssOverwrittenProperties
.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #FFFFFF;
  background: #859bb2;
  background: var(--hoverColor);
  border-color: #859bb2;
  border-color: var(--hoverColor);
}

// .btn-default:active
// .btn-default.active
// .open > .dropdown-toggle.btn-default
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}

// .btn-default.disabled,
// .btn-default[disabled],
// fieldset[disabled] .btn-default,
// .btn-default.disabled:hover,
// .btn-default[disabled]:hover,
// fieldset[disabled] .btn-default:hover,
// .btn-default.disabled:focus,
// .btn-default[disabled]:focus,
// fieldset[disabled] .btn-default:focus,
// .btn-default.disabled.focus,
// .btn-default[disabled].focus,
// fieldset[disabled] .btn-default.focus,
// .btn-default.disabled:active,
// .btn-default[disabled]:active,
// fieldset[disabled] .btn-default:active
// .btn-default.disabled.active,
// .btn-default[disabled].active,
// fieldset[disabled] .btn-default.active
// noinspection CssOverwrittenProperties
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background: #667789;
  background: var(--mainColor);
  border-color: #667789;
  border-color: var(--mainColor);
}

// #contentHeader
#contentHeader {
  // #contentHeader .btn-caption
  .btn-caption {
    font-size: 12px;
  }

  // #contentHeader .caption
  .caption {
    // #contentHeader .caption-text
    &-text {
      font-size: 12px;
      font-weight: bold;
    }

    &-title {
      margin-top: 5px;
      font-size: 13px;
      font-weight: bold;
    }
  }


  // #contentHeader .chb-box
  .chb-box {
    background-color: white;
    margin: 20px;
  }

  @include mediaS {
    display: none;
  }
}

// .drag
.drag {
  color: #006600;
  border: 1px solid #006600;
  padding: 3px;
  cursor: pointer;

  // .dragLayout
  &Layout {
    color: #006600;
  }
}

// .error
.error {
  color: red;
}

// .experte
.experte {
  font-size: 16px;
  padding: 5px;
  font-weight: bold;
}

// .expertennummer
.expertennummer {
  padding: 5px;

  .img {
    margin-top: 5px;
  }
}

// .fav
.fav {
  // .fav-container
  &-container {
    height: 20px;
    width: 20px;
    background-size: cover;
    cursor: pointer;
    margin-bottom: 5px;
  }

  // .fav-no
  &-no {
    background-image: url("../../img/icons/pictogramme/png/impuls-portal_icon_60x60-favoriten_negativ-21.png");
  }

  // .fav-yes
  &-yes {
    background-image: url("../../img/icons/pictogramme/png/impuls-portal_icon_60x60-favoriten-21.png");
  }
}

// .file-caption
.file-caption {
  margin-top: 10px;
}

// #footer
#footer {
  color: white;

  // #footer a
  a {
    color: white;
    text-decoration: none;

    // #footer a:hover
    &:hover {
      text-decoration: underline;
    }
  }

  // #footer-left
  // #footer-right
  &-left,
  &-right {
    padding: 10px;
  }

}

// .footerNav-content
.footerNav-content {
  // .footerNav-content a
  a {
    color: black;
  }

  // .footerNav-content li.title
  li.title {
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  // .footerNav-content ul.category
  ul.category {
    padding: 15px;
    list-style: none;
  }
}

// #formSuche
#formSuche {
  margin-bottom: 10px;
}

// .frontend
.frontend {
  // .frontend .carousel-inner
  .carousel-inner {
    margin-top: 15px;
  }
}

// .geschlecht
.geschlecht {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;;
}

// h4
h4 {
  text-transform: uppercase;
  // h4 > span.strike
  > span.strike {
    display: block;
    margin-bottom: 30px;
  }

}

// .header
.header {

  // .header2Backend
  &2Backend {
    // .header2Backend .strike::after
    .strike::after {
      content: '';
      border-bottom: 2.6px solid white;
      position: absolute;
      left: 0;
      bottom: -10%;
    }
  }

  // .header2Ueberschrift
  &2Ueberschrift {
    margin-left: 10px;
    padding-top: 10px;
  }

  // .header-right
  &-right {
    font-size: 5px;
  }
}

// .headLogin
.headLogin {
  margin: 10px;

}

// .highlighted
.highlighted {
  background: darkgrey;
}

// .hinweisPflichtfelder
.hinweisPflichtfelder {
  font-style: italic;
}

// .icon-dauer,
// .icon-schwierigkeit
.icon-dauer,
.icon-schwierigkeit {
  height:20px;
  width: 20px;
  margin-right:5px;
}

// .img-mittig
.img-mittig {
  margin: 0 auto;
}

// .input
.input {
  // .input-success
  &-success {
    border-color: #3c763d;
  }

  // .input-warning
  &-warning {
    border-color: #8a6d3b;
  }

  // .input-danger
  &-danger {
    border-color: #a94442;
  }
}

// .kontakt
.kontakt {
  // .kontakt .breadcrumb
  .breadcrumb {
    margin-left: 20px;
  }

  // .kontakt form
  form {
    margin-left: 5px;
    // .kontakt form .col-md-12
    .col-md-12 {
      padding: 0;
    }
  }
}

// .login
.login {
  // .loginForm
  &Form {
    // .loginForm a
    a {
      margin-top: 15px;
    }
  }

  // .loginHeader
  &Header {
    /*display:none;*/
    padding: 0 15px 15px 15px;
    position: absolute;
    bottom: 10%;
    right: 5%;
    width: 35%;
    max-width: 1170px;
    color: #fff;
    /*text-align: center;*/
    /*line-height: 2.5em;*/
    border-top: 1px solid #ababab;
    background: #667789; /* Fallback IE 6-8 */
    background: var(--mainColor); /* Fallback IE 6-8 */


    // .loginHeader
    a {
      margin-top: 10px;
      text-decoration: none;
      color: white;
    }

    // .loginHeader input
    input {
      //noinspection CssOverwrittenProperties
      color: #667789;
      //noinspection CssOverwrittenProperties
      color: var(--mainColor);
      margin: 5px 0 5px 0;
    }

    // .loginHeader label
    label {
      display: none;
    }

    // .loginHeader h4
    h4 {
      padding-left: 16px;
    }

    // .loginHeader .btn-login
    .btn-login {
      float: left;
      border: 1px solid white;
    }

    // .loginHeader .form-group
    .form-group {
      margin-bottom: 0;
    }

    @include mediaS {
      position: static;
      width: auto;
    }

    @include mediaM {
      bottom: auto;
      top: 0;
    }
  }
}

// .logo
.logo {
  // .logo-streit
  &-streit {
    width: 240px;
  }

  // .logo-portal
  &-portal {
    width: 90%;
    @include mediaS {
      width: 80%;
    }
  }
}

// #mainContent
#mainContent {
  // #mainContent #aktuelles
  #aktuelles {
    // #mainContent #aktuelles .carousel-caption
    .carousel-caption {
      color: black;
      background: rgba(256, 256, 256, 0.7);
      width: 100%;
      padding-left: 30px;
      text-align: left;
      left: 0;
      right: 0;
      padding-bottom: 15px;
      // #mainContent #aktuelles .carousel-caption p
      p {
        font-size: 16px;

      }
    }

    // #mainContent #aktuelles .carousel-indicators
    .carousel-indicators {
      right: 5%;
      left: auto;
      width: auto;
      margin-left: 0;

      // #mainContent #aktuelles .carousel-indicators .active
      .active {
        background: #667789;
        background: var(--mainColor);
        height: 12px;
        margin: 0;
        width: 12px;
      }

      // #mainContent #aktuelles .carousel-indicators li
      li {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #667789;
        //noinspection CssOverwrittenProperties
        border: 1px solid var(--mainColor);
        border-radius: 10px;
        cursor: pointer;
        display: inline-block;
        height: 10px;
        margin: 0;
        text-indent: -999px;
        width: 10px;
        text-align: left;
      }
    }

  }

  // #mainContent .anwendung
  .anwendung {
    // #mainContent .anwendung .col-md-4
    .col-md-4 {
      padding: 5px;
    }
  }

  // #mainContent .btn-bearbeiten
  .btn-bearbeiten {
    margin-top: 10px;
    margin-left: 5px;
  }

  // mainContent .btn-search-aktuelles
  .btn-search-aktuelles {
    margin-top: 0;
  }

  // #mainContent .fileinput-remove-button
  .fileinput-remove-button {
    margin-top: 10px;
  }

  // #mainContent h4
  h4 {
    text-transform: uppercase;
  }

  // #mainContent li
  li {
    list-style: circle;
  }

  // #mainContent p
  p {
    font-size: 12px;
  }

  // #mainContent .tooltip
  .tooltip {
    opacity: 1;

    // #mainContent .tooltip-inner
    &-inner {
      opacity: 1;
      color: black;
      background: #E6E6E6;
      border-radius: 4px;
      padding: 10px;
      border: 1px solid #919191;
    }

    // #mainContent .tooltip-arrow
    &-arrow {
      border-bottom-color: #919191;
    }
  }
}

// .mousePointer
.mousePointer {
  cursor: pointer;
}

// .noBorder
.noBorder {
  border: 0 solid grey !important;
}

// .noShadow
.noShadow {

}

// p
p {
  // p.filterPanel
  &.filterPanel {
    height: 5px;
  }
}

// .preNews
.preNews {
  border: transparent;
  background: transparent;
  max-width: 523px;
  overflow: auto;
  white-space: pre-wrap;
  padding: 0 1%;
}

// .profil
.profil {
  margin-top: 0;
  color: white;

  // .profilbox
  &box {
    margin-top: 10px;
    height: 60px;
    background: white;
    font-size: 10px;
    padding: 2px;
  }

  // .profilHeader
  &Header {
    padding: 15px;

  }

  // .profilIcon
  &Icon {
    left: 33%;
    top: 33%;

    // .profilIconfeld
    //noinspection CssOverwrittenProperties
    &feld {
      width: 40px;
      height: 40px;
      color: #667789;
      color: var(--mainColor);
      background: white;
    }
  }

  // .profiluebersicht
  &uebersicht {
    // .profiluebersicht .anwendung
    .anwendung {
      margin-left: -20px;
    }
  }
}

// .overlay
.overlay {
  // .overlay img
  img {
    display: block;
  }
}

// .responsive
.responsive {
  // .responsive-video
  &-video {
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;

    // .responsive-video iframe
    iframe {
      width: 100%;
      max-width: 868px;
      max-height: 434px;
    }

    // .responsive-video video
    video {
      width: 100%;
      max-width: 868px;
      max-height: 434px;
    }
  }
}

// .rollover
.rollover {
  height: 60px;
  width: 60px;
  color: transparent;
  background: transparent;
  font-size: 10px;
  text-align: center;

  // .rollover:hover
  &:hover {
    height: 60px;
    width: 60px;
    color: white;
    background: #667789;
    background: var(--mainColor);
    opacity: 0.7;

    // .rollover:hover img
    img {
      display: none;
    }
  }
}

// .rueck
.rueck {
  margin-left: 4%;
  font-size: 12px;
}

// .shadow:hover
// .schatten
.schatten,
.shadow:hover {
  -webkit-box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.44);
  box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.44);
}

// #sidebarCalendar
#sidebarCalendar {
  // #sidebarCalendar h2
  h2 {
    font-size: 18px;
  }
}

// .sidebar h4 > span.strike
.sidebar {
  // .sidebarRight
  // .sidebarLeft
  &Right,
  &Left {
    // .sidebarRight h4
    // .sidebarLeft h4
    h4 {
      // .sidebarRight h4 > span.strike
      // .sidebarLeft h4 > span.strike
      > span.strike {
        display: block;
        margin-bottom: 20px;
      }
    }
  }
}

// span
span {
  // span#mail
  &#mail {
    padding: 5px;

    // span#mail a
    a {
      color: white;
    }
  }
}

// .strike
.strike {
  position: relative;

  // .strike::after
  &::after {
    content: '';
    border-bottom: 2.6px solid #667789;
    border-bottom: 2.6px solid var(--mainColor);
    position: absolute;
    left: 0;
    bottom: -30%;
    width: 38px;
  }
}

// .styleanwendung
.styleanwendung {
  // .styleanwendung a:hover
  a:hover {
    text-decoration: none;
  }

  // .styleanwendung .btn
  .btn {
    margin: 10px;
  }

  // .styleanwendung .carousel-control
  .carousel-control {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    filter: alpha(opacity=50);
    opacity: 0.5;

    // .styleanwendung .carousel-control:hover
    // .styleanwendung .carousel-control:focus
    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
      filter: alpha(opacity=90);
      outline: 0;
      opacity: 0.9;
    }


    // .styleanwendung .carousel-control .icon-prev
    // .styleanwendung .carousel-control .icon-next
    // .styleanwendung .carousel-control .glyphicon-chevron-left
    // .styleanwendung .carousel-control .glyphicon-chevron-right
    .icon-prev,
    .icon-next,
    .glyphicon-chevron-left,
    .glyphicon-chevron-right {
      position: absolute;
      top: 50%;
      z-index: 5;
      display: inline-block;
    }

    // .styleanwendung .carousel-control .icon-prev
    // .styleanwendung .carousel-control .glyphicon-chevron-left
    .icon-prev,
    .glyphicon-chevron-left {
      left: 50%;
      margin-left: -10px;
    }

    // .styleanwendung .carousel-control .icon-next
    // .styleanwendung .carousel-control .glyphicon-chevron-right {
    .icon-next,
    .glyphicon-chevron-right {
      right: 50%;
      margin-right: -10px;
    }

    // .styleanwendung .carousel-control .icon-prev
    // .styleanwendung .carousel-control .icon-next
    .icon-prev,
    .icon-next {
      width: 20px;
      height: 20px;
      margin-top: -10px;
      font-family: serif;
      line-height: 1;
    }

    // .styleanwendung .carousel-control .icon-prev:before
    .icon-prev:before {
      content: '\2039';
    }

    // .styleanwendung .carousel-control .icon-next:before
    .icon-next:before {
      content: '\203a';
    }

  }

  // .styleanwendung .row
  .row {
    margin-left: 10px;
  }
}

// .suchfeld
.suchFeld {
  padding: 10px;
  border-radius: 4px;
}

// table
table {
  .btn-link,
  .btn-left:hover {
    color: #FFF;
  }
}

// .team
.team {
  margin: 5px;
  padding: 5px;
}

// .textCenter
.textCenter {
  text-align: center;
}

@include mediaS {
  #navigation, #header-right, #footerNav > div:not(#footer) {
    display: none;
    visibility: hidden;
  }
  #header2-content {
    display: none;
  }
}