// .pagination
.pagination {
  // .pagination > .active > a,
  // .pagination > .active > a:focus,
  // .pagination > .active > a:hover,
  // .pagination > .active > span,
  // .pagination > .active > span:focus
  // .pagination > .active > span:hover
  > .active > a,
  > .active > a:focus,
  > .active > a:hover,
  > .active > span,
  > .active > span:focus,
  > .active > span:hover {
    background: #667789;
    background: var(--mainColor);
    border-color: #667789;
    border-color: var(--mainColor);
    color: #fff;
    cursor: default;
    z-index: 2;
  }

  // .pagination > li
  > li {
    // .pagination > li > a,
    // .pagination > li > span
    a,
    span {
      color: #333333;
      background: #FFFFFF;
      border: 1px solid #8c8c8c;

      // .pagination > li > a:hover,
      // .pagination > li > span:hover,
      // .pagination > li > a:focus,
      // .pagination > li > span:focus
      &:hover,
      &:focus {
        font-weight: bold;
        background: #e6e6e6;
        border-color: #e6e6e6;
      }
    }

  }
}