// .popover
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  min-width: 230px;
  max-width: 276px;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #e6e6e6;
  border: 1px solid rgba(0, 0, 0,0);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  // .popover.bottom
  &.bottom {
    margin-top: 10px;

    // .popover.bottom > .arrow
    > .arrow {
      top: -11px;
      left: 6%;
      margin-left: -11px;
      border-top-width: 0;
      border-bottom-color: #e6e6e6;
      border-bottom-color: rgba(0, 0, 0, 0.25);

      // .popover.bottom > .arrow:after
      &:after {
        top: 1px;
        margin-left: -10px;
        content: " ";
        border-top-width: 0;
        border-bottom-color: #e6e6e6;
      }
    }
  }

  // .popover-content
  &-content {
    font-size: 12px;
    padding: 3px 9px 8px;
  }

  // .popover.left
  &.left {
     margin-left: -10px;

    // .popover.left > .arrow
    > .arrow {
      top: 50%;
      right: -11px;
      margin-top: -11px;
      border-right-width: 0;
      border-left-color: #e6e6e6;
      border-left-color: rgba(0, 0, 0, 0.25);

      // .popover.left > .arrow:after
      &:after {
        right: 1px;
        bottom: -10px;
        content: " ";
        border-right-width: 0;
        border-left-color: #e6e6e6;
      }
    }
  }

  // .popover.right
  &.right {
    margin-left: 10px;

    // .popover.right > .arrow
    > .arrow {
      top: 50%;
      left: -11px;
      margin-top: -11px;
      border-right-color: #e6e6e6;
      border-right-color: rgba(0, 0, 0, 0.25);
      border-left-width: 0;

      // .popover.right > .arrow:after
      &:after {
        bottom: -10px;
        left: 1px;
        content: " ";
        border-right-color: #e6e6e6;
        border-left-width: 0;
      }

    }
  }

  // .popover-title
  &-title {
    padding: 8px 8px 3px;
    margin: 0;
    font-size: 14px;
    background-color: #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 5px 5px 0 0;

    // .popover-title h4
    h4 {
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 0;
    }

    // .popover-title span
    span {
      font-size: 12px;
    }
  }

  // .popover.top
  &.top {
    margin-top: -10px;

    // .popover.top > .arrow
    > .arrow {
      bottom: -11px;
      left: 6%;
      margin-left: -11px;
      border-top-color: #e6e6e6;
      border-top-color: rgba(0, 0, 0, 0.25);
      border-bottom-width: 0;

      // .popover.top > .arrow:after
      &:after {
        bottom: 1px;
        margin-left: -10px;
        content: " ";
        border-top-color: #e6e6e6;
        border-bottom-width: 0;
      }
    }

  }

  // .popover > .arrow
  > .arrow {
    border-width: 11px;

    // .popover > .arrow
    // .popover > .arrow:after
    &,
    &:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
    }

    // .popover > arrow:after
    &:after {
      content: "";
      border-width: 10px;
    }
  }

  // .popover label
  label {
    font-size: 13px;
  }
}