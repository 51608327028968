@mixin mediaS {
  @media (max-width: 767px) {
    @content
  }
}

@mixin mediaM {
  @media (min-width: 768px) and (max-width: 991px){
    @content
  }
}

@mixin mediaL {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content
  }
}

@mixin mediaXL {
  @media (min-width: 1200px) {
    @content
  }
}

