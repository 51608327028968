/* ******************************************************************************************************** */
/* calendar
/* ******************************************************************************************************** */
.fc-day-header {
  background: #667789;
  background: var(--mainColor);
  color: #FFFFFF;
}

.fc-day-header a {
  background: #667789;
  background: var(--mainColor);
  color: #FFFFFF;
}

.fc-ltr .fc-basic-view .fc-day-number {
  text-align: left;
  font-size: 18px;
}

.fc-event:hover {
  cursor: pointer;
}

.calLegende {
  display: inline-block;
  width: 10px;
  height: 10px;
}

.fc-center h2{
  font-size:20px;
}

.modal-header.hotline {
  background:white;
  color:black;
}

.anzeigeWochentage .btn-group {
  margin-left: 0;
}

#mainContent .anzeigeWochentage .btn {
  margin: 0;
  padding: 3px 6px;
}