// #navigation
#navigation {
  // #navigation nav
  nav {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    // #navigation nav a
    a {
      color: white;
    }

    // #navigation nav .dropdown-menu a
    .dropdown-menu {
      a {
        color: #333;
      }
    }

    .input-group {
      a {
        color: #667789;
        color: var(--mainColor);
      }
    }

  }

  // #navigation .affixed
  .affixed {
    position: fixed;
  }
}

// .navbar
.navbar {
  // .navbar-default
  &-default {
    border: 0;
    background: none;
    filter: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    // .navbar-default .navbar-nav
    .navbar-nav {
      // .navbar-default .navbar-nav > .active
      // .navbar-default .navbar-nav > .open
      > .active,
      > .open {
        // .navbar-default .navbar-nav > .active a
        // .navbar-default .navbar-nav > .open a
        a {
          background-image: linear-gradient(to bottom, #FFFFFF 0px, #FFFFFF 100%);
          background-repeat: repeat-x;
        }
      }

      // .navbar-default .navbar-brand
      // .navbar-default .navbar-nav > li > a
      // .navbar-default .navbar-nav > li > div > a
      .navbar-brand,
      .navbar-nav > li > a,
      .navbar-nav > li > div > a {
        color: #fff;
      }


    }

    // .navbar-default .navbar-brand:hover
    // .navbar-default .navbar-nav > li:hover
    // .navbar-default .navbar-nav > li > a:hover
    // .navbar-default .navbar-nav > li > a:focus
    .navbar-brand:hover,
    .navbar-nav > li:hover,
    .navbar-nav > li > a:hover,
    .navbar-nav > li > a:focus {
      background: #859bb2;
      background: var(--hoverColor);
      color: #FFFFFF;
    }
  }

}

// .nav-tabs
.nav-tabs {
  // .nav-tabs li.active
  li.active {
    font-weight: bold;
  }
}