// .modal
.modal {
  // .modal-header
  &-header {
    background: #667789;
    background: var(--mainColor);
    color: white;

    // .modal-header .close
    .close {
      color: #fff;
      opacity: 1;
    }
  }

  // .modal-body
  &-body {
    // .modal-body .well
    .well {
      background: #E6E6E6;
    }
  }

  // .modalEdit
  // .modalDelete
  &Edit,
  &Delete {
    cursor: pointer;
  }

  // .modal-success
  &-success {
    .modal-header {
      background: #AAD797;
      color: black;
    }
  }

  // .modal-error
  &-error {
    .modal-header {
      background: #D1423C;
      color: black;
    }
  }

  // .modal-lg
  &-lg {
    margin: 0 auto;
  }

  &-dialog {
    @include mediaS {
      top: 50px;
    }
  }
}
