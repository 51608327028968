// .mm
.mm {
  // .mm-close:after
  .mm-close:after {
    right: 23px;
  }

  // .mm-opened
  &-opened {
    // .mm-opened .hamburger__container
    .hamburger__container {
      width: 100%;
    }
  }

  // .mm-panels
  &-panels {
    // .mm-panels > .mm-panel
    > .mm-panel {
      // .mm-panels > .mm-panel:after
      &:after {
        height: 0;
      }
      // .mm-panels > .mm-panel > .mm-listview
      > .mm-listview {
        margin: 20px -20px 0;
      }
    }

  }
}

// .menu-horizontal
.menu-horizontal{
  list-style: none;
  padding: 0;
  margin: 0;

  // .menu-horizontal li
  li {
    display: inline-block;

    // .menu-horizontal li:not(:first-child):before
    &:not(:first-child):before {
      content: "| "
    }
  }
}

// .hamburger
//noinspection CssOverwrittenProperties
.hamburger {
  padding: 10px 10px;
  display: block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  -o-transition-property: opacity, filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: .15s;
  -o-transition-duration: .15s;
  transition-duration: .15s;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background: #667789;
  background: var(--mainColor);
  margin: 0;
  overflow: visible;
  outline: none;
  border-radius: 0 0 0 5px;
  border: 1px solid #e6e6e6;
  border-top: none;
  border-right: none;
  float: right;

  // .hamburger-box
  &-box {
    position: relative;
    top: +2px;
    z-index: 100;
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  // .hamburger__container
  &__container {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;

  }

  // .hamburger:hover
  &:hover {
    opacity: 1
  }

  // .hamburger-inner
  &-inner {
    display: block;
    top: 50%;
    margin-top: -2px;

    // .hamburger-inner
    // .hamburger-inner:after
    // .hamburger-inner:before
    &,
    &:after,
    &:before {
      width: 40px;
      height: 4px;
      background-color: #fff;
      border-radius: 4px;
      position: absolute;
      -webkit-transition-property: -webkit-transform;
      transition-property: -webkit-transform;
      -o-transition-property: -o-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform, -o-transform;
      -webkit-transition-duration: .15s;
      -o-transition-duration: .15s;
      transition-duration: .15s;
      -webkit-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease
    }

    // .hamburger-inner:after
    // .hamburger-inner:before
    &:after,
    &:before {
      content: "";
      display: block
    }

    // .hamburger-inner:before
    &:before {
      top: -10px
    }

    // .hamburger-inner:after
    &:after {
      bottom: -10px
    }

  }

  // .hamburger--spin
  &--spin {
    // .hamburger--spin .hamburger-inner
    .hamburger-inner {
      -webkit-transition-duration: .22s;
      -o-transition-duration: .22s;
      transition-duration: .22s;
      -webkit-transition-timing-function: cubic-bezier(.55, .055, .675, .19);
      -o-transition-timing-function: cubic-bezier(.55, .055, .675, .19);
      transition-timing-function: cubic-bezier(.55, .055, .675, .19);

      // .hamburger--spin .hamburger-inner:after
      &:after {
        -webkit-transition: bottom .1s ease-in .25s, -webkit-transform .22s cubic-bezier(.55, .055, .675, .19);
        transition: bottom .1s ease-in .25s, -webkit-transform .22s cubic-bezier(.55, .055, .675, .19);
        -o-transition: bottom .1s .25s ease-in, -o-transform .22s cubic-bezier(.55, .055, .675, .19);
        transition: bottom .1s ease-in .25s, transform .22s cubic-bezier(.55, .055, .675, .19);
        transition: bottom .1s ease-in .25s, transform .22s cubic-bezier(.55, .055, .675, .19), -webkit-transform .22s cubic-bezier(.55, .055, .675, .19), -o-transform .22s cubic-bezier(.55, .055, .675, .19);
      }

      // .hamburger--spin .hamburger-inner:before
      &:before {
        -webkit-transition: top .1s ease-in .25s, opacity .1s ease-in;
        -o-transition: top .1s .25s ease-in, opacity .1s ease-in;
        transition: top .1s ease-in .25s, opacity .1s ease-in
      }

      // .hamburger--spin .hamburger-inner .is-active
      .is-active {
        // .hamburger--spin .hamburger-inner .is-active .hamburger-inner
        .hamburger-inner {
          -webkit-transform: rotate(225deg);
          -ms-transform: rotate(225deg);
          -o-transform: rotate(225deg);
          transform: rotate(225deg);
          -webkit-transition-delay: .12s;
          -o-transition-delay: .12s;
          transition-delay: .12s;
          -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
          -o-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
          transition-timing-function: cubic-bezier(.215, .61, .355, 1);

          // .hamburger--spin .hamburger-inner .is-active .hamburger-inner:before
          &:before {
            top: 0;
            opacity: 0;
            -webkit-transition: top .1s ease-out, opacity .1s ease-out .12s;
            -o-transition: top .1s ease-out, opacity .1s .12s ease-out;
            transition: top .1s ease-out, opacity .1s ease-out .12s;
          }

          // .hamburger--spin .hamburger-inner .is-active .hamburger-inner:after
          &:after {
            bottom: 0;
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
            -webkit-transition: bottom .1s ease-out, -webkit-transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
            transition: bottom .1s ease-out, -webkit-transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
            -o-transition: bottom .1s ease-out, -o-transform .22s .12s cubic-bezier(.215, .61, .355, 1);
            transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
            transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s, -webkit-transform .22s cubic-bezier(.215, .61, .355, 1) .12s, -o-transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
          }

        }
      }

    }

    // .hamburger--spin.is-active
    &.is-active {
      // .hamburger--spin.is-active .hamburger-inner
      .hamburger-inner {
        -webkit-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        -o-transform: rotate(225deg);
        transform: rotate(225deg);
        -webkit-transition-delay: .12s;
        -o-transition-delay: .12s;
        transition-delay: .12s;
        -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
        -o-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
        transition-timing-function: cubic-bezier(.215, .61, .355, 1);

        // .hamburger--spin.is-active .hamburger-inner:after
        &:after {
          bottom: 0;
          -webkit-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          transform: rotate(-90deg);
          -webkit-transition: bottom .1s ease-out, -webkit-transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
          transition: bottom .1s ease-out, -webkit-transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
          -o-transition: bottom .1s ease-out, -o-transform .22s .12s cubic-bezier(.215, .61, .355, 1);
          transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
          transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s, -webkit-transform .22s cubic-bezier(.215, .61, .355, 1) .12s, -o-transform .22s cubic-bezier(.215, .61, .355, 1) .12s
        }

        // .hamburger--spin.is-active .hamburger-inner:before
        &:before {
          top: 0;
          opacity: 0;
          -webkit-transition: top .1s ease-out, opacity .1s ease-out .12s;
          -o-transition: top .1s ease-out, opacity .1s .12s ease-out;
          transition: top .1s ease-out, opacity .1s ease-out .12s
        }
      }
    }


  }
}



@include mediaS {
  .mm-navbar > form {
    padding: 0 10px;
  }
  .mm-navbar .input-group-btn .btn {
    margin-top: 0;
  }
  .mm-menu .mm-tileview.mm-listview > li.mm-tile-s {
    width: 25%;
    padding: 0;
    height: auto;
  }
  .mm-menu .mm-tileview.mm-listview > li.mm-tile-s > a {
    padding: 10%;
    line-height: 1em;
    position: static;
  }
  .mm-menu .mm-tileview.mm-listview > li.mm-tile-s > a > img {
    width: 100%;
    height: auto;
  }
  .mm-tileview.mm-listview + .mm-listview {
    margin-top: 0;
  }
}