@each $color, $value in $background-colors {
  .backgroundColor#{$color} {
    background-color: $value;
  }
}

@each $color, $value in $text-colors {
  .textColor#{$color} {
    color: $value;
  }
}